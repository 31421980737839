.conteinerCadastraProduto{
    max-width: 720px;
    margin: 4rem auto;
    padding: 0 2rem;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    form{
        display: flex;
        flex-direction: column;
        margin: 1rem 0;

        input{
            border-radius: 0.3rem;
            margin-bottom: 1rem;
            border: 1px solid #000000;
            height: 2rem;
            padding: 0 0.5rem;
        }
        
        input{
            border-radius: 0.3rem;
            margin-bottom: 1rem;
            border: 1px solid #000000;
            height: 2rem;
            padding: 0 0.5rem;
        }
        select{
            border-radius: 0.3rem;
            margin-bottom: 1rem;
            border: 1px solid #000000;
            height: 2rem;
            padding: 0 0.5rem;
        }

        button{
            border-radius: 0.3rem;
            height: 2rem;
            border: 0;
            font-size: 1.2rem;
            font-weight: bold;
            background-color: #1ee2b4b0;
        }

        label{
            width: 100%;
            height: 320px;
            background-color: #afcb14;
            margin-bottom: 1rem;
            border-radius: 0.3rem;

            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            input{
                display: none;
            }

            span{
                z-index: 99;
                position: absolute;
                opacity: 0.7;
                transition: all 0.7s;
            }

            span:hover{
                opacity: 1;
                transform: scale(1.2);
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: inherit;
                border-radius: 0.3rem;
            }
        }
    }
}