.conteinerProdutos {
    display: flex;
    flex-direction: column;
    
    h1 {
        background-color: #00ffff;
        height: 5rem;
        padding: 1rem;
        text-align: center;
    }
    
}
.mapProdutos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .cardProdutos {
        padding: 0.5rem;
        border-radius: 5px;
        box-shadow: 7px 7px 13px 0px rgb(9, 209, 35);
        padding: 30px;
        margin: 20px;
        width: 300px;
        height: 420px;
        transition: all 0.5s ease-out;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            height: 10rem;
            mix-blend-mode: multiply; //remover Fundo da Imagem
        }

        p{
            font-size: 1.5rem;
            font-weight: bold;
            margin-top: 1rem;
        }
    }
}