.conteinerDashboard {
    display: flex;
    flex-direction: column;

    h1{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0aa843;
        width: 100%;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }    
}
.linksDashboard{ 
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    
    h2{
        background-color: #e619e2;
        border-radius: 0.5rem;
        width: 100%;
        padding: 0.3rem;
        text-align: center;
    }
    
    a{
        text-decoration: none;
        color: #FFFFFF;
        font-weight: bolder;
    }
}

.buttonDashboard{
    display: flex;
    justify-content: center;
    align-items: center;

    button{
        margin-top: 3rem;
        width: 20%;
        background-color: #2d18d3;
        color: #FFFFFF;
        font-size: 2rem;
        font-weight: bold;
        border-radius: 0.5rem;
        border-width: 0;        
    }
}